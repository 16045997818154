import { useSession } from "next-auth/client";
import SignIn from "../components/login";
import dynamic from "next/dynamic";
import ContactComponent from "../components/contacts";

export default function Home() {
  const [session, loading] = useSession();
  const Main = dynamic(() => import("../components/main"));

  return (
    <>
      {!loading && (
        <>
          {!session ? (
            <>
              <SignIn />
            </>
          ) : (
            <>
              {session.role !== "Data-Entry-Officer" ? (
                <Main />
              ) : (
                <ContactComponent />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
